export default [
  {
    name: "base",
    data: {},
    items: [
      {
        name: "topChairModel",
        source: "/assets/models/topChair.glb",
        type: "model",
      },
      { name: "rubiksCube", source: "/assets/models/Rubik.glb" },
      {
        name: "skyTexture",
        source: "/assets/textures/sao.png",
        type: "texture",
      },
      {
        name: "perlin",
        source: "/assets/textures/perlin.png",
        type: "texture",
      },
      {
        name: "baked1",
        source: "/assets/textures/baked1.ktx2",
        type: "texture",
      },
      {
        name: "baked2",
        source: "/assets/textures/baked2.ktx2",
        type: "texture",
      },
      {
        name: "baked3",
        source: "/assets/textures/baked3.ktx2",
        type: "texture",
      },
      {
        name: "texture_paint",
        source: "/assets/textures/texture_paint.png",
        type: "texture",
      },
      { name: "_roomModel", source: "/assets/models/room.glb" },
      { name: "_roomModel2", source: "/assets/models/room2.glb" },
      { name: "_roomModel3", source: "/assets/models/room3.glb" },
      { name: "whiteboard", source: "/assets/models/whiteboard.glb" },
      { name: "leftMonitor", source: "/assets/models/leftMonitor.glb" },
      { name: "rightMonitor", source: "/assets/models/rightMonitor.glb" },
      { name: "arcadeMachine", source: "/assets/models/arcadeMachine.glb" },
      { name: "linkedin", source: "/assets/models/linkedin.glb" },
      { name: "github", source: "/assets/models/github.glb" },
      { name: "itchio", source: "/assets/models/itchio.glb" },
      {
        name: "cubeTexture",
        source: [
          "/assets/environmentMaps/nx.jpg",
          "/assets/environmentMaps/ny.jpg",
          "/assets/environmentMaps/nz.jpg",
          "/assets/environmentMaps/px.jpg",
          "/assets/environmentMaps/py.jpg",
          "/assets/environmentMaps/pz.jpg",
        ],
        type: "cubeTexture",
      },
      { name: "markerOpen", source: "assets/sounds/marker-open.mp3" },
      { name: "eraser", source: "assets/sounds/eraser.mp3" },
      { name: "confetti", source: "assets/sounds/confetti.mp3" },
      { name: "partyblower", source: "assets/sounds/partyblower.mp3" },
      { name: "footstep01", source: "assets/sounds/footstep01.ogg" },
      { name: "footstep02", source: "assets/sounds/footstep02.ogg" },
      { name: "footstep03", source: "assets/sounds/footstep03.ogg" },
      { name: "mouseclick", source: "assets/sounds/mouseclick.ogg" },
      { name: "mouserelease", source: "assets/sounds/mouserelease.ogg" },
      { name: "rubik_1", source: "assets/sounds/rubik_1.mp3" },
      { name: "rubik_2", source: "assets/sounds/rubik_2.mp3" },
      { name: "rubik_3", source: "assets/sounds/rubik_3.mp3" },
      { name: "start", source: "assets/sounds/start.mp3" },
      { name: "vase_break", source: "assets/sounds/vase_break.mp3" },
      { name: "door", source: "assets/sounds/door.mp3" },
      { name: "trophy", source: "assets/sounds/trophy.mp3" },
      { name: "trophy_platinum", source: "assets/sounds/trophy_platinum.mp3" },
      { name: "floral", source: "assets/sounds/floral.mp3" },
      { name: "hit", source: "assets/sounds/hit.ogg" },
      { name: "tetris", source: "assets/sounds/tetris.ogg" },
      { name: "die", source: "assets/sounds/die.ogg" },
      { name: "whoosh", source: "assets/sounds/whoosh.mp3" },
      { name: "select1", source: "assets/sounds/select1.ogg" },
      { name: "select2", source: "assets/sounds/select2.ogg" },
      { name: "screnshot", source: "assets/social/screenshot.png" },
    ],
  },
];
